import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "../Dialog/dialog";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles(styles);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function CustomTable(props) {
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    isNoAction,
    isNoPagination,
    isNoActionDelete,
  } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("calories");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  React.useEffect(() => {
    props.paginationDetails({ page, rowsPerPage });
  }, [page, rowsPerPage]);

  const filterData = (searchKey, data) => {
    let searchInputString = searchKey.trim();
    let arrayData = data;
    if (searchInputString.length) {
      let regexSearchInput = new RegExp(searchInputString, "i");
      let filteredArray = Array.isArray(arrayData[0])
        ? arrayData.filter((eachValue) => {
            for (let property of eachValue) {
              if (property.toString().match(regexSearchInput)) {
                return eachValue;
              }
            }
          })
        : arrayData.filter((eachValue) => {
            for (let property in eachValue) {
              if (typeof eachValue[property] === "object") {
                for (let propety1 in eachValue[property]) {
                  if (
                    eachValue[property][propety1]
                      .toString()
                      .match(regexSearchInput)
                  ) {
                    return eachValue;
                  }
                }
              }
              if (eachValue[property].toString().match(regexSearchInput)) {
                return eachValue;
              }
            }
          });
      return filteredArray;
    }
    return arrayData;
  };

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
              {!isNoAction && (
                <TableCell
                  className={classes.tableCell + " " + classes.tableHeadCell}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.length === 0 && (
            <TableRow>
              <TableCell>
                {props.isLoading ? (
                  <CircularProgress disableShrink color="primary" />
                ) : (
                  "Data Not Available"
                )}
              </TableCell>
              {tableHead.map((elm, indx) => {
                return <TableCell key={indx}></TableCell>;
              })}
            </TableRow>
          )}
          {tableData.length > 0 && props.isBookings
            ? stableSort(
                filterData(props.searchKey, tableData),
                getComparator(order, orderBy)
              ).map((prop, indx) => {
                return (
                  <TableRow key={indx} className={classes.tableBodyRow}>
                    {prop.map((pro, key) => {
                      return (
                        <>
                          {key !== 3 ? (
                            <TableCell className={classes.tableCell} key={key}>
                              {Array.isArray(pro)
                                ? pro.map((elm, ind) => {
                                    return <div key={ind}>{elm}</div>;
                                  })
                                : pro}
                            </TableCell>
                          ) : (
                            <TableCell className={classes.tableCell} key={key}>
                              {
                                <Checkbox
                                  checked={pro}
                                  onChange={(event) =>
                                    props.handleChange(event, indx)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              }
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                );
              })
            : tableData.length > 0 &&
              stableSort(
                filterData(props.searchKey, tableData),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((prop, key) => {
                  return (
                    <TableRow key={key} className={classes.tableBodyRow}>
                      {prop.map((pro, key) => {
                        return (
                          <TableCell className={classes.tableCell} key={key}>
                            {Array.isArray(pro)
                              ? pro.map((elm, ind) => {
                                  return <div key={ind}>{elm}</div>;
                                })
                              : pro}
                          </TableCell>
                        );
                      })}
                      {!isNoAction && (
                        <TableCell className={classes.tableCell} key={key}>
                          {
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                maxWidth: 120,
                              }}
                            >
                              <span style={{ margin: "0 5px" }}>
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => props.editFunctionality(prop)}
                                />
                              </span>
                              {!isNoActionDelete && (
                                <Dialog
                                  buttonName={
                                    <DeleteIcon style={{ cursor: "pointer" }} />
                                  }
                                  title="Delete Confirmation"
                                  fields={[
                                    {
                                      name: "Do you want to delete this ?",
                                      element:
                                        "If you delete the data will be removed permenently.",
                                    },
                                  ]}
                                  buttonStyles={{
                                    variant: "text",
                                    styles: {
                                      color: "",
                                      textTransform: "capitalize",
                                      padding: 0,
                                    },
                                  }}
                                  submitButton={{
                                    name: "Delete",
                                    functn: () =>
                                      props.deleteFunctionality(prop),
                                  }}
                                />
                              )}
                            </div>
                          }
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
        </TableBody>
      </Table>
      {!isNoPagination && props.isBookings ? (
        <TablePagination
          rowsPerPageOptions={[30, 60, 100]}
          component="div"
          count={props.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        !isNoPagination && (
          <TablePagination
            rowsPerPageOptions={[30, 60, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
