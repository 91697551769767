import React from "react"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
// import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

export default props => {
  return (
    <div>
      {/* <h3>
        Bookings <span style={{ fontSize: 14 }}> - Pending </span>
      </h3> */}

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {props.headers.map((header, i) => (
                <TableCell
                  key={"tbl" + i}
                  style={{ color: "#888", fontSize: 14 }}
                >
                  {header.displayValue}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, i) => (
              <TableRow role="checkbox" tabIndex={-1}>
                {props.headers.map((header, i) => (
                  <TableCell
                    key={"tbld" + i}
                    // style={{ color: "#888", fontSize: 14 }}
                  >
                    {header.key !== null &&
                      !header.component &&
                      row[header.key]}
                    {header.component && header.component(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}
