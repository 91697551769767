import React from "react";
import SnackBar from "../../components/Snackbar/Snackbar";

var connection =
  navigator.connection ||
  navigator.mozConnection ||
  navigator.webkitConnection ||
  navigator.onLine;

class NetworkChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnack: false,
      snackMessage: "",
      snackColor: "success"
    };
  }
  async componentDidMount() {
    this.updateConnectionStatus();
    connection.addEventListener("change", this.updateConnectionStatus);
  }
  updateConnectionStatus = () => {
    if (!navigator.onLine) {
      this.setState({
        openSnack: true,
        snackMessage: "Currently you are offline!",
        snackColor: "danger"
      });
    } else {
      this.setState({ openSnack: false, snackMessage: "", snackColor: "" });
    }
  };
  render() {
    return (
      <SnackBar
        open={this.state.openSnack}
        message={this.state.snackMessage}
        color={this.state.snackColor ? this.state.snackColor : "info"}
        closeSnack={() => this.setState({ openSnack: false })}
        disableHide={true}
      />
    );
  }
}
export default NetworkChecker;
