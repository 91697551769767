import React from "react";
import TableList from "../TableList/TableList.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "../../components/Dialog/dialog";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";
import "../../assets/css/blog.css";

export default class Career extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitButtonName: "",
      open: false,
      answer: "",
      answers: [],
      career: [],
      modifiedCareer: [],
      title: "",
      experience: "",
      salaryPackage: "",
      location: "",
      qualification: "",
      message: "",
      openSnack: false,
      snackMessage: "",
      snackColor: "",
      isLoading: false,
    };
  }

  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };

  handleInput = (e, field) => {
    let value;
    if (e.target.value === "true") value = true;
    else if (e.target.value === "false") value = false;
    else value = e.target.value;
    this.setState({ [field]: value, [field + "ErrorMessage"]: "" });
  };

  paginationDetails = (pagination) => {
    this.setState({ pagination }, () => this.getblogData());
  };

  getblogData = () => {
    let { page, rowsPerPage } = this.state.pagination;
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .get("/career?skip=" + page * rowsPerPage + "&limit=" + rowsPerPage, {
        // .get("/career", {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState(
            {
              career: resp.data,
            },
            () => this.modifyblogs()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  modifyblogs = () => {
    let careers = this.state.career;

    let modifiedCareer = [];
    for (let i = 0; i < careers.length; i++) {
      let career = [];
      // let response = blogs[i].responses;
      career.push(careers[i].title);
      career.push(careers[i].experience);
      career.push(careers[i].salaryPackage);
      career.push(careers[i].location);
      career.push(careers[i].qualification);
      modifiedCareer.push(career);
    }
    this.setState({ modifiedCareer });
  };

  editFunctionality = (rows) => {
    let careers = this.state.career;
    careers.push(rows);
    this.setState({
      career: careers,
    });
    let selectedCareer =
      careers[this.findFieldIndex(careers, rows[1], "experience")]?.id;
    this.setState(
      {
        open: true,
        title: rows[0],
        experience: rows[1],
        salaryPackage: rows[2],
        location: rows[3],
        qualification: rows[4],
        submitButtonName: "Update",
        selectedCareer,
        titleErrorMessage: "",
        experienceErrorMessage: "",
        salaryPackageErrorMessage: "",
        locationErrorMessage: "",
        qualificationErrorMessage: "",
      },
      () => this.clearErrorFields()
    );
    console.log(rows, selectedCareer);
    this.makeOpenStateFalse();
  };

  deleteFunctionality = (rows) => {
    let modifiedCareer = this.state.modifiedCareer;
    let careers = this.state.career;
    let careerId =
      careers[this.findFieldIndex(careers, rows[1], "experience")]?.id;
    let token = JSON.parse(localStorage.session).token;
    request
      .delete("/career/" + careerId, {
        headers: { token },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({
            openSnack: true,
            snackColor: "success",
            snackMessage: "Record deleted suceesfully",
          });
          let index = this.findFieldIndex(modifiedCareer, rows);
          if (index > -1) {
            modifiedCareer.splice(index, 1);
          }
          this.setState({ modifiedCareer });
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  makeOpenStateFalse = () => {
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  addOrUpdateJob = () => {
    let blogRec = {};
    let okToSubmit = true;
    let fields = [
      { name: "title", value: this.state.title },
      { name: "experience", value: this.state.experience },
      { name: "salaryPackage", value: this.state.salaryPackage },
      { name: "location", value: this.state.location },
      { name: "qualification", value: this.state.qualification },
    ];

    let isUpdateRequest = this.state.submitButtonName === "Update";
    let titleValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[0].value);
    let experienceValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[1].value
    );
    let salaryPackageValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[2].value
    );
    let locationValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[3].value);
    let qualificationValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[4].value
    );

    let filedValidators = [
      titleValidation,
      experienceValidation,
      salaryPackageValidation,
      locationValidation,
      qualificationValidation,
    ];

    for (let i = 0; i < filedValidators.length; i++) {
      if (filedValidators[i].valid) {
        blogRec[fields[i].name] = fields[i]?.value;
      } else {
        okToSubmit = false;
        this.setState({
          [fields[i].name + "ErrorMessage"]:
            fields[i].name + " can not be empty.",
          open: true,
        });
      }
    }

    this.makeOpenStateFalse();
    let token = JSON.parse(localStorage.session).token;

    const updateDetails = {
      title: this.state.title,
      experience: this.state.experience,
      salaryPackage: this.state.salaryPackage,
      location: this.state.location,
      qualification: this.state.qualification,
    };

    if (okToSubmit) {
      if (isUpdateRequest) {
        this.setState({ isLoading: true });
        request
          .put("/career/" + this.state.selectedCareer, updateDetails, {
            headers: { token },
          })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record updated suceesfully",
              });
              this.getblogData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      } else {
        blogRec.user = JSON.parse(localStorage.session).user;

        var submitDetails = {
          title: this.state.title,
          experience: this.state.experience,
          salaryPackage: this.state.salaryPackage,
          location: this.state.location,
          qualification: this.state.qualification,
        };
        this.setState({ isLoading: true });
        request
          .post("/career/", submitDetails, { headers: { token } })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record added suceesfully",
              });
              this.getblogData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      }
    }
  };

  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };

  clearErrorFields = () => {
    if (this.state.answers.length && this.state.question) {
      this.setState({
        titleErrorMessage: "",
        experienceErrorMessage: "",
        salaryPackageErrorMessage: "",
        locationErrorMessage: "",
        qualificationErrorMessage: "",
        answers: [],
      });
    }
  };

  clearStates = (e) => {
    this.setState({
      answers: [],
      submitButtonName: "",
      title: "",
      experience: "",
      salaryPackage: "",
      location: "",
      qualification: "",
    });
  };

  render() {
    const fields = [
      {
        name: "title",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.titleErrorMessage,
        value: this.state.title,
      },
      {
        name: "experience",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.experienceErrorMessage,
        value: this.state.experience,
      },
      {
        name: "salaryPackage",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.salaryPackageErrorMessage,
        value: this.state.salaryPackage,
      },
      {
        name: "location",
        type: "url",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.locationErrorMessage,
        value: this.state.location,
      },
      {
        name: "qualification",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.qualificationErrorMessage,
        value: this.state.qualification,
      },
    ];
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          <Dialog
            buttonName="Add Job"
            submitButton={{
              name: this.state.submitButtonName,
              functn: () => this.addOrUpdateJob(),
            }}
            fields={fields}
            open={this.state.open}
            isLoading={this.state.isLoading}
            clearErrorFields={this.clearErrorFields}
            clearStates={this.clearStates}
          />
          <TableList
            className="row"
            tableHead={[
              "Title",
              "Experience",
              "Package",
              "Location",
              "Qualification",
            ]}
            tableData={this.state.modifiedCareer}
            tableHeading="Career"
            tableSubHeading="List of Jobs"
            editFunctionality={this.editFunctionality}
            deleteFunctionality={this.deleteFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
