/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Users from "views/Users/Users.js";
import Reminders from "views/Reminders/Reminders.js";
import WorkOutline from "@material-ui/icons/WorkOutline";
import Work from "@material-ui/icons/Work";
// import MediaMentions from 'views/MediaMentions/MediaMentions.js';
import Blogs from "views/Blogs/Blogs.js";
import Career from "views/Career/Career.js";

import SweetScore from "views/SweetScore/SweetScore.js";
import Bookings from "views/Bookings/Bookings";
import Consultation from "views/Consultation/Consultation";
import MoneyIcon from "@material-ui/icons/Money";

const dashboardRoutes = [
  {
    path: "/manageUsers",
    name: "Users",
    icon: Person,
    component: Users,
    layout: "/admin",
  },
  {
    path: "/manageSweetScore",
    name: "Sweet Score",
    icon: QuestionAnswerIcon,
    component: SweetScore,
    layout: "/admin",
  },
  {
    path: "/manageReminders",
    name: "Reminders",
    icon: "content_paste",
    component: Reminders,
    layout: "/admin",
  },
  {
    path: "/manageBookings",
    name: "Bookings",
    icon: AssignmentIcon,
    component: Bookings,
    layout: "/admin",
  },
  {
    path: "/manageConsultation",
    name: "Consultation",
    icon: MoneyIcon,
    component: Consultation,
    layout: "/admin",
  },
  // {
  //   path: "/manageMediaMentions",
  //   name: "MediaMentions",
  //   icon: "content_paste",
  //   component: MediaMentions,
  //   layout: "/admin",
  // },
  {
    path: "/manageBlogs",
    name: "Blogs",
    icon: "content_paste",
    component: Blogs,
    layout: "/admin",
  },
  {
    path: "/manageCareer",
    name: "Career",
    icon: WorkOutline,
    component: Career,
    layout: "/admin",
  } /* ,
  {
    path: "/manageJobApplication",
    name: "Job Applications",
    icon: Work,
    component: Blogs,
    layout: "/admin",
  }, */,
];

export default dashboardRoutes;
