import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList(props) {
  const classes = useStyles();
  const [searchKey, setSearchKey] = React.useState("");
  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
              <h4
                style={{ display: "flex", flex: 2 }}
                className={classes.cardTitleWhite}
              >
                {props.tableHeading ? props.tableHeading : "Simple Table"}
              </h4>
              {!props.isNoSearch && (
                <input
                  style={{
                    background: "transparent",
                    border: "none",
                    borderBottom: "0.5px solid #fafafa",
                    padding: "0 0 0 10px",
                    color: "white",
                  }}
                  id="searchbar"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              )}
            </div>
            <p className={classes.cardCategoryWhite}>
              {props.tableSubHeading
                ? props.tableSubHeading
                : "Here is a subtitle for this table"}
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              {...props}
              searchKey={searchKey}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
