import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TableList from "../TableList/TableList.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";

class Consultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultation: [],
      open: false,
      fees: "",
      openSnack: false,
      snackMessage: "",
      snackColor: "success",
    };
  }
  componentDidMount = () => {
    this.getConsultation()
  }
  getConsultation = () => {
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request.get("/consultation/pricing", {
      headers: {
        token,
      },
    }).then((resp) => {
      this.setState({ isLoading: false });
      if (resp.status === 200) {
        let consultation = [[resp.data.price]]
        this.setState({ consultation });
      } else {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(resp.data),
        });
      }
    })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  }
  paginationDetails = () => { };
  editFunctionality = (rows) => {
    let fees = this.state.consultation[0][0];
    this.setState({ open: true, fees });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleUpdate = () => {
    let obj = { price: this.state.fees }
    let token = JSON.parse(localStorage.session).token;
    request
      .put("/consultation/pricing", obj, {
        headers: { token },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({
            openSnack: true,
            snackColor: "success",
            snackMessage: "Consultation updated suceesfully",
            open: false
          }, () => this.getConsultation());
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };
  render() {
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Consultation</DialogTitle>
            <DialogContent dividers={true}>
              <div style={{ margin: "20px 0 20px 0" }}>
                <label style={{ textTransform: "uppercase" }} htmlFor="fees">
                  <b>Fees</b>
                </label>
                <div>
                  <input
                    type="input"
                    id="fees"
                    placeholder="Enter Fees"
                    onChange={(e) => this.setState({ fees: e.target.value })}
                    style={{
                      padding: "3px 8px",
                    }}
                    value={this.state.fees}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleUpdate} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>
          <TableList
            tableHead={["Consultation Fees"]}
            tableData={this.state.consultation || []}
            tableHeading="Consultation"
            tableSubHeading="Price for the Consultation"
            editFunctionality={this.editFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
            isNoActionDelete={true}
            isNoSearch={true}
            isNoPagination={true}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default Consultation;
