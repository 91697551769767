import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import { withRouter } from "react-router";
import "../../assets/css/loginIn.css";
import Dialog from "../../components/Dialog/dialog";
import request from "../../core/apiClient/request";
import SnackBar from "../../components/Snackbar/Snackbar";

class Addtask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorEmail: "",
      password: "",
      errorPassword: "",
      fields: [
        {
          name: "email",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          openSnack: false,
          snackMessage: "",
          snackColor: "success",
        },
      ],
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleLoginPress = this.handleLoginPress.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleEnterPress = this.handleEnterPress.bind(this);
  }
  handleEnterPress(event) {
    if (event.which === 13) {
      this.handleLoginPress();
    }
  }
  findFieldIndex = (field) => {
    return this.state.fields.findIndex((ele) => {
      return ele.name === field;
    });
  };
  handleInput = (e, field) => {
    let fields = this.state.fields;
    fields[this.findFieldIndex(field)].errorMessage = "";
    this.setState({ [field]: e.target.value, fields });
  };
  handleEmailChange(event) {
    this.setState({ email: event.target.value, errorEmail: "" });
  }
  handlePassword(e) {
    this.setState({ password: e.target.value, errorPassword: "" });
  }
  validateEmail(email) {
    const pattern = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const result = pattern.test(email);
    if (result === true) {
      return { valid: true, message: "Valid Email" };
    } else {
      this.setState({ email: "" });
      return { valid: false, message: "Invalid Email" };
    }
  }
  validatePassword(e) {
    if (e !== "") {
      return { valid: true };
    } else {
      return { valid: false };
    }
  }

  saveUserDetails(userRecord) {
    console.log(userRecord);
    console.log(typeof userRecord);
    try {
      localStorage.setItem("userRecord", JSON.stringify(userRecord));
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  }
  saveSessionDetails(session) {
    try {
      localStorage.setItem("session", JSON.stringify(session));
    } catch (error) {
      // Error saving data
      console.log(error);
    }
  }
  handleResetPasswordSubmit = () => {
    let okToSubmit = true;
    let fields = this.state.fields;
    let user = {};
    let emailValidation = this.validateEmail(this.state.email);
    if (emailValidation.valid) user.email = this.state.email;
    else {
      okToSubmit = false;
      fields[this.findFieldIndex("email")].errorMessage =
        "Please Enter Valid email";
      this.setState({ fields });
    }
    if (okToSubmit) {
      console.log("user", user);
    }
  };
  handleLoginPress() {
    let user = {};
    let okToLogin = true;
    let emailValidation = this.validateEmail(this.state.email);
    let passwordValidation = this.validatePassword(this.state.password);

    if (emailValidation.valid) user.phone = this.state.email;
    else {
      okToLogin = false;
      this.setState({ errorEmail: "Please Enter Valid email" });
    }
    if (passwordValidation.valid) user.password = this.state.password;
    else {
      okToLogin = false;
      this.setState({ errorPassword: "Please Enter Password" });
    }

    if (okToLogin) {
      request
        .post("/login", user)
        .then((resp) => {
          if (resp.status === 200) {
            console.log(resp);
            this.saveUserDetails(resp.data.userRecord);
            this.saveSessionDetails(resp.data.session);
            this.props.history.push("/");
          } else {
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(resp.data.error),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(err),
            isLoading: false,
          });
        });
    }
  }
  render() {
    return (
      <div
        className="login-main-container"
        style={{
          backgroundColor: "whitesmoke",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <div
          className="login-input-container"
          style={{
            height: "500px",
            width: "380px",
            border: "1px solid #0747A6",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: 50,
          }}
        >
          <div
            className="login-image-container"
            style={{
              width: "100%",
              height: 150,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: "#0747A6",
              flexDirection: "column",
              color: "white",
              fontSize: "15pt",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <img
              src={require("../../assets/img/login.png")}
              style={{ height: 70, width: 70 }}
              alt=""
            />
            LOGIN
          </div>
          <div
            className="login-input-field-container"
            style={{
              display: "flex",
              flexDirection: "column",
              height: 80,
              marginTop: 40,
            }}
          >
            <div
              className="login-email-input-container"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "white",
                border: "1px solid #0747A6",
                height: 55,
                borderRadius: "9px",
              }}
            >
              <div>
                <input
                  onKeyPress={this.handleEnterPress}
                  type="email"
                  placeholder="Email"
                  style={{
                    height: 50,
                    width: "250px",
                    border: "none",
                    margin: 5,
                  }}
                  autoComplete="email"
                  onChange={this.handleEmailChange}
                />
              </div>
              <div
                className="input-field-icon-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0747A6",
                  width: 50,
                  height: 55,
                  color: "white",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                <PersonIcon style={{ fontSize: "30px" }} />
              </div>
            </div>
            <div style={{ color: "red" }}>{this.state.errorEmail}</div>
          </div>

          <div
            className="login-input-field-container"
            style={{
              display: "flex",
              flexDirection: "column",
              height: 80,
            }}
          >
            <div
              className="login-email-input-container"
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "white",
                border: "1px solid #0747A6",
                height: 55,
                borderRadius: "9px",
              }}
            >
              <div>
                <input
                  onKeyPress={this.handleEnterPress}
                  type="password"
                  placeholder="Password"
                  style={{
                    height: 50,
                    width: "250px",
                    border: "none",
                    margin: 5,
                  }}
                  onChange={this.handlePassword}
                />
              </div>
              <div
                className="input-field-icon-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0747A6",
                  width: 50,
                  height: 55,
                  color: "white",
                  borderTopRightRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                <LockIcon style={{ fontSize: "30px" }} />
              </div>
            </div>
            <div style={{ color: "red" }}>{this.state.errorPassword}</div>
          </div>
          <div
            className="input-submit-button-container"
            style={{ borderRadius: "9px" }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#0747A6",
                color: "white",
                fontSize: 19,
                height: 55,
                width: "250px",
                borderRadius: "9px",
              }}
              onClick={() => this.handleLoginPress()}
            >
              SUBMIT
            </Button>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <div style={{ fontSize: 11, marginRight: 4 }}>
              Forgot Password ?
            </div>
            <div style={{ fontSize: 12 }}>
              <Dialog
                buttonName="Click Here"
                isUpdate={false}
                fields={this.state.fields}
                buttonStyles={{
                  variant: "text",
                  styles: {
                    color: "blue",
                    textTransform: "capitalize",
                    fontSize: 12,
                  },
                }}
                submitButton={{ functn: this.handleResetPasswordSubmit }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Addtask);
