import React from "react";
import TableList from "../TableList/TableList.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "../../components/Dialog/dialog";
import request from "../../core/apiClient/request";
import SnackBar from "../../components/Snackbar/Snackbar";
export default class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      fields: [
        {
          name: "name",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
        },
        {
          name: "email",
          type: "email",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
        },
        {
          name: "phone",
          type: "number",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
        },
        {
          name: "role",
          isInputField: false,
          element: (
            <select
              className="role"
              onChange={(e) => this.handleInput(e, "role")}
            >
              <option value="">Select</option>
              <option value="superadmin">Super Admin</option>
              <option value="admin">Admin</option>
              <option disabled={true} value="normal-user">
                Normal User
              </option>
            </select>
          ),
          errorMessage: "",
          value: "",
        },
        {
          name: "password",
          type: "password",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          disabled: false,
        },
      ],
      submitButtonName: "",
      updatedUsers: [],
      open: false,
      role: "",
      pagination: {},
      isLoading: false,
      openSnack: false,
      snackMessage: "",
      snackColor: "success",
      userRecord: {},
      selectedUser: "",
    };
  }
  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };
  handleInput = (e, field) => {
    let fields = this.state.fields;
    fields[this.findFieldIndex(fields, field, "name")].errorMessage = "";
    fields[this.findFieldIndex(fields, field, "name")].value = e.target.value;
    this.setState({ fields });
  };
  static getDerivedStateFromProps(props, state) {
    let userRecord = localStorage.userRecord;
    if (userRecord) {
      userRecord = JSON.parse(userRecord);
    }
    return {
      userRecord,
    };
  }
  componentDidMount() {
    this.getUsers();
  }
  modifyUsersDataStructure = () => {
    let users = this.state.users;
    let updatedUsers = [];
    for (let i = 0; i < users.length; i++) {
      let user = [];
      user.push(users[i].name);
      user.push(users[i].email);
      user.push(users[i].phone);
      user.push(users[i].role);
      updatedUsers.push(user);
    }
    this.setState({ updatedUsers });
  };
  paginationDetails = (pagination) => {
    console.log(pagination);
    this.setState({ pagination });
  };
  addOrUpdateUser = () => {
    console.log("add user");
    let user = {};
    let okToSubmit = true;
    let fields = this.state.fields;
    let isUpdateRequest = this.state.submitButtonName === "Update";
    let nameValidation = this.validate(/^[a-zA-z .]{2,}/g, fields[0].value);
    let emailValidation = this.validate(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      fields[1].value
    );
    let phoneValidation = this.validate(/^[0-9]{10}$/g, fields[2].value);
    let roleValidation = this.validate(/[a-zA-Z-]{5,}/g, fields[3].value);
    let passwordValidation = !isUpdateRequest
      ? this.validate(/(.|\s)*\S(.|\s)*/, fields[4].value)
      : { valid: true };
    let filedValidators = [
      nameValidation,
      emailValidation,
      phoneValidation,
      roleValidation,
      passwordValidation,
    ];
    for (let i = 0; i < filedValidators.length; i++) {
      if (filedValidators[i].valid) user[fields[i].name] = fields[i].value;
      else {
        okToSubmit = false;
        fields[i].errorMessage = "Invalid " + fields[i].name;
      }
    }
    this.setState({ fields });
    let token = JSON.parse(localStorage.session).token;
    if (okToSubmit) {
      if (isUpdateRequest) {
        console.log("update", user);
        this.setState({ isLoading: true });
        request
          .put("/user?id=" + this.state.selectedUser, user, {
            headers: { token },
          })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "User updated suceesfully",
              });
              this.getUsers();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      } else {
        this.setState({ isLoading: true });
        request
          .post("/signup", user, { headers: { token } })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "User added suceesfully",
              });
              this.getUsers();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      }
    }
  };
  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };
  clearErrorFields = () => {
    let fields = this.state.fields;
    for (let i = 0; i < fields.length; i++) {
      fields[i].errorMessage = "";
      fields[i].value = "";
    }
    this.setState({ fields, submitButtonName: "" });
  };
  getUsers = () => {
    let { page, rowsPerPage } = this.state.pagination;
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .get("/users?skip=" + page * rowsPerPage + "&limit=" + rowsPerPage, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          console.log(resp.data);
          this.setState(
            {
              users: resp.data,
            },
            () => this.modifyUsersDataStructure()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };
  editFunctionality = (rows) => {
    this.setState({ open: true, submitButtonName: "Update" });
    let users = this.state.users;
    let selectedUser = users[this.findFieldIndex(users, rows[2], "phone")].id;
    let fields = this.state.fields;
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = rows[i];
      this.timer = setTimeout(() => {
        if (!fields[i].isInputField) {
          document.getElementsByClassName(fields[i].name)[0].value = rows[i];
          if (fields[i].name === "role" && rows[i] === "superadmin") {
            document
              .getElementsByClassName(fields[i].name)[0]
              .setAttribute("disabled", "disabled");
          } else {
            document
              .getElementsByClassName(fields[i].name)[0]
              .removeAttribute("disabled");
          }
        }
        document
          .getElementById("password")
          .setAttribute("disabled", "disabled");
        this.setState({ open: false });
      }, 200);
    }
    this.setState({ fields, selectedUser });
  };
  deleteFunctionality = (rows) => {
    this.setState({
      openSnack: true,
      snackColor: "danger",
      snackMessage: "Users can not be deleted.",
    });
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  render() {
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          {this.state.userRecord &&
            this.state.userRecord.role === "superadmin" && (
              <Dialog
                buttonName="Add User"
                submitButton={{
                  name: this.state.submitButtonName,
                  functn: this.addOrUpdateUser,
                }}
                fields={this.state.fields}
                open={this.state.open}
                clearErrorFields={this.clearErrorFields}
                isLoading={this.state.isLoading}
              />
            )}
          <TableList
            tableHead={["Name", "Email", "Phone", "Role"]}
            tableData={this.state.updatedUsers}
            tableHeading="Users"
            tableSubHeading="List of users available"
            editFunctionality={this.editFunctionality}
            deleteFunctionality={this.deleteFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
