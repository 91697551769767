import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../assets/css/blog.css";
import { BorderColor } from "@material-ui/icons";

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    if (props.clearStates) props.clearStates(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.clearErrorFields) {
      props.clearErrorFields();
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  React.useEffect(() => {
    if (props.open) {
      setOpen(true);
    }
  }, [props.open]);

  const handleSubmitCLick = () => {
    props.submitButton.functn();
    let errorMessage = props.fields.find((ele) => {
      return ele.errorMessage ? ele.errorMessage !== "" : false;
    });
    if (!errorMessage) handleClose();
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
      <Button
        style={
          props.buttonStyles
            ? props.buttonStyles.styles
            : { background: "#984faf", color: "#fafafa" }
        }
        variant={
          props.buttonStyles
            ? props.buttonStyles.variant
              ? props.buttonStyles.variant
              : "contained"
            : "contained"
        }
        onClick={handleClickOpen}
      >
        {props.buttonName ? props.buttonName : "ADD"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {props.title ? props.title : "Fill Details"}
        </DialogTitle>
        <DialogContent dividers={true}>
          {props.fields &&
            props.fields.map((elem, index) => {
              return (
                <div key={index} style={{ margin: "10px 0" }}>
                  <label
                    style={{ textTransform: "uppercase" }}
                    htmlFor={elem.name}
                  >
                    <b>{elem.name}</b>
                  </label>
                  <div
                    id={
                      !elem.isInputField ? elem.name : elem.name + "Container"
                    }
                  >
                    {elem.isInputField ? (
                      elem.name === "paragraph" ? (
                        <textarea
                          type={elem.type}
                          id={elem.name}
                          placeholder={elem.name}
                          onChange={(e) => elem.functn(e, elem.name)}
                          style={{
                            padding: "3px 0",
                            width: "99.5%",
                            height: "100px",
                            border: "1px solid #e5e5e5",
                          }}
                          disabled={elem.disabled}
                          value={elem.value}
                          onKeyPress={(event) => {
                            if (event.which === 13) handleSubmitCLick();
                          }}
                        />
                      ) : (
                        <input
                          type={elem.type}
                          id={elem.name}
                          placeholder={elem.name}
                          onChange={(e) => elem.functn(e, elem.name)}
                          style={{
                            padding: "3px 0",
                            width: "99.5%",
                            height: "20px",
                            border: "1px solid #e5e5e5",
                          }}
                          disabled={elem.disabled}
                          value={elem.value}
                          onKeyPress={(event) => {
                            if (event.which === 13) handleSubmitCLick();
                          }}
                        />
                      )
                    ) : (
                      elem.element
                    )}
                    <div style={{ fontSize: 13, color: "red" }}>
                      {elem.errorMessage}
                    </div>
                  </div>
                </div>
              );
            })}
        </DialogContent>
        <DialogActions>
          {props.isLoading ? (
            <CircularProgress disableShrink color="primary" />
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={props.isLoading}
                onClick={handleSubmitCLick}
                color="primary"
              >
                {props.submitButton && props.submitButton.name
                  ? props.submitButton.name
                  : "Submit"}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
