import React from "react";
import TableList from "../TableList/TableList.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "../../components/Dialog/dialog";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";
import "../../assets/css/blog.css";

export default class Blogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitButtonName: "",
      open: false,
      answer: "",
      answers: [],
      blog: [],
      modifiedblog: [],
      title: "",
      subTitle: "",
      imageTitle: "",
      blogImage: "",
      author: "",
      date: "",
      paragraph: [],
      message: "",
      openSnack: false,
      snackMessage: "",
      snackColor: "",
      isLoading: false,
    };
  }

  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };

  handleInput = (e, field) => {
    let value;
    if (e.target.value === "true") value = true;
    else if (e.target.value === "false") value = false;
    else value = e.target.value;
    this.setState({ [field]: value, [field + "ErrorMessage"]: "" });
  };

  paginationDetails = (pagination) => {
    this.setState({ pagination }, () => this.getblogData());
  };

  getblogData = () => {
    let { page, rowsPerPage } = this.state.pagination;
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .get("/blog?skip=" + page * rowsPerPage + "&limit=" + rowsPerPage, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState(
            {
              blog: resp.data,
            },
            () => this.modifyblogs()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  modifyblogs = () => {
    let blogs = this.state.blog;

    let modifiedblog = [];
    for (let i = 0; i < blogs.length; i++) {
      let blog = [];
      // let response = blogs[i].responses;
      blog.push(blogs[i].title);
      blog.push(blogs[i].subTitle);
      blog.push(blogs[i].imageTitle);
      blog.push(blogs[i].blogImage);
      blog.push(blogs[i].author);
      blog.push(blogs[i].date);
      blog.push(blogs[i].paragraph);
      modifiedblog.push(blog);
    }
    this.setState({ modifiedblog });
  };

  editFunctionality = (rows) => {
    let blogs = this.state.blog;
    blogs.push(rows);
    this.setState({
      blog: blogs,
    });
    let selectedblog =
      blogs[this.findFieldIndex(blogs, rows[1], "subTitle")]?.id;
    this.setState(
      {
        open: true,
        title: rows[0],
        subTitle: rows[1],
        imageTitle: rows[2],
        blogImage: rows[3],
        author: rows[4],
        date: rows[5],
        paragraph: rows[6],
        submitButtonName: "Update",
        selectedblog,
        titleErrorMessage: "",
        subtitleErrorMessage: "",
        imagetitleErrorMessage: "",
        blogimageErrorMessage: "",
        authorErrorMessage: "",
        dateErrorMesaage: "",
        paragraphErrorMessage: "",
      },
      () => this.clearErrorFields()
    );
    console.log(rows, selectedblog);
    this.makeOpenStateFalse();
  };

  deleteFunctionality = (rows) => {
    let modifiedblog = this.state.modifiedblog;
    let blogs = this.state.blog;
    let blogId = blogs[this.findFieldIndex(blogs, rows[1], "subTitle")]?.id;
    let token = JSON.parse(localStorage.session).token;
    request
      .delete("/blog/" + blogId, {
        headers: { token },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({
            openSnack: true,
            snackColor: "success",
            snackMessage: "Record deleted suceesfully",
          });
          let index = this.findFieldIndex(modifiedblog, rows);
          if (index > -1) {
            modifiedblog.splice(index, 1);
          }
          this.setState({ modifiedblog });
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  makeOpenStateFalse = () => {
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  addOrUpdateblog = () => {
    let blogRec = {};
    let okToSubmit = true;
    let fields = [
      { name: "title", value: this.state.title },
      { name: "subTitle", value: this.state.subTitle },
      { name: "imageTitle", value: this.state.imageTitle },
      { name: "blogImage", value: this.state.blogImage },
      { name: "author", value: this.state.author },
      { name: "date", value: this.state.date },
      { name: "paragraph", value: [this.state.paragraph] },
    ];

    let isUpdateRequest = this.state.submitButtonName === "Update";
    let titleValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[0].value);
    let subtitleValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[1].value);
    let imagetitleValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[2].value
    );
    let blogimageValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[3].value
    );
    let authorValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[4].value);
    let dateValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[5].value);
    let paragraphValidation = this.validate(
      /(.|\s)*\S(.|\s)*/,
      fields[6].value
    );

    let filedValidators = [
      titleValidation,
      subtitleValidation,
      imagetitleValidation,
      blogimageValidation,
      authorValidation,
      dateValidation,
      paragraphValidation,
    ];

    for (let i = 0; i < filedValidators.length; i++) {
      if (filedValidators[i].valid) {
        blogRec[fields[i].name] = fields[i]?.value;
      } else {
        okToSubmit = false;
        this.setState({
          [fields[i].name + "ErrorMessage"]:
            fields[i].name + " can not be empty.",
          open: true,
        });
      }
    }

    this.makeOpenStateFalse();
    let token = JSON.parse(localStorage.session).token;

    const updateDetails = {
      title: this.state.title,
      subTitle: this.state.subTitle,
      imageTitle: this.state.imageTitle,
      blogImage: this.state.blogImage,
      author: this.state.author,
      date: this.state.date,
      paragraph: [this.state.paragraph],
    };

    if (okToSubmit) {
      if (isUpdateRequest) {
        this.setState({ isLoading: true });
        request
          .put("/blog/" + this.state.selectedblog, updateDetails, {
            headers: { token },
          })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record updated suceesfully",
              });
              this.getblogData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      } else {
        blogRec.user = JSON.parse(localStorage.session).user;

        var submitDetails = {
          title: this.state.title,
          subTitle: this.state.subTitle,
          imageTitle: this.state.imageTitle,
          blogImage: this.state.blogImage,
          author: this.state.author,
          date: this.state.date,
          paragraph: [this.state.paragraph],
        };
        this.setState({ isLoading: true });
        request
          .post("/blog/", submitDetails, { headers: { token } })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record added suceesfully",
              });
              this.getblogData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      }
    }
  };

  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };

  clearErrorFields = () => {
    if (this.state.answers.length && this.state.question) {
      this.setState({
        titleErrorMessage: "",
        subtitleErrorMessage: "",
        imagetitleErrorMessage: "",
        blogimageErrorMessage: "",
        authorErrorMessage: "",
        paragraphErrorMessage: "",
        answers: [],
      });
    }
  };

  clearStates = (e) => {
    this.setState({
      answers: [],
      submitButtonName: "",
      title: "",
      subTitle: "",
      imageTitle: "",
      blogImage: "",
      author: "",
      date: "",
      paragraph: []
    });
  };

  render() {
    const fields = [
      {
        name: "title",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.titleErrorMessage,
        value: this.state.title,
      },
      {
        name: "subTitle",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.subTitleErrorMessage,
        value: this.state.subTitle,
      },
      {
        name: "imageTitle",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.imageTitleErrorMessage,
        value: this.state.imageTitle,
      },
      {
        name: "blogImage",
        type: "url",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.blogImageErrorMessage,
        value: this.state.blogImage,
      },
      {
        name: "author",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.authorErrorMessage,
        value: this.state.author,
      },
      {
        name: "date",
        type: "date",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.dateErrorMessage,
        value: this.state.date,
      },
      {
        name: "paragraph",
        type: "array",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.paragraphErrorMessage,
        value: [this.state.paragraph],
      },
    ];
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          <Dialog
            buttonName="Add Blog"
            submitButton={{
              name: this.state.submitButtonName,
              functn: () => this.addOrUpdateblog(),
            }}
            fields={fields}
            open={this.state.open}
            isLoading={this.state.isLoading}
            clearErrorFields={this.clearErrorFields}
            clearStates={this.clearStates}
          />
          <TableList
            className="row"
            tableHead={[
              "Title",
              "Sub Title",
              "Image Title",
              "Blog Image",
              "Athour",
              "Date",
              "Paragraph",
            ]}
            tableData={this.state.modifiedblog}
            tableHeading="Blog"
            tableSubHeading="List of Blogs"
            editFunctionality={this.editFunctionality}
            deleteFunctionality={this.deleteFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
