import axios from "axios";
const API_URL = "https://api.dentamitra.com";
// const API_URL = "http://localhost:1337";
// const API_URL = "https://dmitapi.rayabharitechnologies.com/";

// const API_URL = "https://dmitapi.rayabharitechnologies.com";

const request = axios.create({
  baseURL: API_URL,
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  // withCredentials: true
  validateStatus: function (status) {
    return true; // default
  },
});

export default request;
