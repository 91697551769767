import React from "react";
import Button from "@material-ui/core/Button";
import TableList from "../TableList/TableList.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "../../components/Dialog/dialog";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";

export default class ReminderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitButtonName: "",
      updatedUsers: [],
      open: false,
      role: "",
      answer: "",
      answers: [],
      Reminder: [],
      modifiedReminder: [],
      daily_reminder: true,
      title: "",
      message: "",
      recurrence: 1,
      time: "",
      ans: "",
      openSnack: false,
      snackMessage: "",
      snackColor: "",
      isLoading: false,
    };
  }

  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };

  handleInput = (e, field) => {
    let value;
    if (e.target.value === "true") value = true;
    else if (e.target.value === "false") value = false;
    else value = e.target.value;
    console.log(typeof value, value, field);
    if (field === "time") this.setState({ repeat_onErrorMessage: "" });
    this.setState({ [field]: value, [field + "ErrorMessage"]: "" });
  };

  paginationDetails = (pagination) => {
    console.log(pagination);
    this.setState({ pagination }, () => this.getReminderData());
  };

  getReminderData = () => {
    let { page, rowsPerPage } = this.state.pagination;
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .get("/reminder?skip=" + page * rowsPerPage + "&limit=" + rowsPerPage, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        console.log(resp);
        if (resp.status === 200) {
          this.setState(
            {
              Reminder: resp.data,
            },
            () => this.modifyReminders()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  modifyReminders = () => {
    let Reminders = this.state.Reminder;
    let modifiedReminder = [];
    for (let i = 0; i < Reminders.length; i++) {
      let Reminder = [];
      let repeatOn;
      let response = Reminders[i].responses;
      Reminder.push(Reminders[i].title);
      Reminder.push(Reminders[i].message);
      Reminder.push(Reminders[i].isDailyReminder ? "Yes" : "No");
      if (Reminders[i].isDailyReminder && !Reminders[i].isDisabled) {
        repeatOn =
          response[0].recurrence > 1
            ? "Every " + response[0].recurrence + " days at " + response[0].time
            : "Everyday at " + response[0].time;
      } else if (!Reminders[i].isDailyReminder && !Reminders[i].isDisabled) {
        repeatOn = [];
        for (let j = 0; j < response.length; j++) {
          repeatOn[j] =
            "After " +
            response[j].time +
            " days for " +
            response[j].ans +
            " Users.";
        }
      }
      Reminder.push(repeatOn);
      modifiedReminder.push(Reminder);
    }
    this.setState({ modifiedReminder });
  };

  editFunctionality = (rows) => {
    let reminders = this.state.Reminder;
    console.log(reminders, "reminders data here.");
    let selectedReminder =
      reminders[this.findFieldIndex(reminders, rows[1], "message")].id;
    this.setState({
      open: true,
      title: rows[0],
      message: rows[1],
      submitButtonName: "Update",
      selectedReminder,
    });
    console.log(selectedReminder);
    if (rows[2] === "Yes") {
      let repeatOn = rows[3].split("at");
      this.setState({
        daily_reminder: true,
        time: repeatOn[1].replace(" ", ""),
        recurrence:
          repeatOn[0] === "Everyday "
            ? 1
            : parseInt(repeatOn[0].match(/\d+/g)[0]),
      });
    } else {
      let answers = [];
      for (let k = 0; k < rows[3].length; k++) {
        let obj = {};
        let repeatOn = rows[3][k].split(" days for ");
        obj.time = parseInt(repeatOn[0].match(/\d+/g)[0]);
        obj.ans = repeatOn[1].substring(0, repeatOn[1].length - 7);
        answers.push(obj);
      }
      this.setState({ daily_reminder: false, answers });
    }
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  deleteFunctionality = (rows) => {
    let modifiedReminder = this.state.modifiedReminder;
    let Reminders = this.state.Reminder;
    let reminderId =
      Reminders[this.findFieldIndex(Reminders, rows[1], "message")].id;
    console.log(reminderId);
    let token = JSON.parse(localStorage.session).token;
    request
      .delete("/reminder/" + reminderId, {
        headers: { token },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({
            openSnack: true,
            snackColor: "success",
            snackMessage: "Record deleted suceesfully",
          });
          let index = this.findFieldIndex(modifiedReminder, rows);
          if (index > -1) {
            modifiedReminder.splice(index, 1);
          }
          this.setState({ modifiedReminder });
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };
  addAnswerToArray = () => {
    let answers = this.state.answers;
    if (this.state.ans && this.state.time) {
      answers.push({ ans: this.state.ans, time: this.state.time });
    }
    this.setState({ ans: "", time: "", answers }, () => console.log(answers));
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  deleteAnswer = (id) => {
    let answers = this.state.answers;
    if (id > -1) {
      answers.splice(id, 1);
    }
    this.setState({ answers });
    this.getReminderData();
  };
  makeOpenStateFalse = () => {
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  addOrUpdateReminder = () => {
    console.log("add reminder");
    let reminderRec = {};
    let okToSubmit = true;
    let fields = [
      { name: "title", value: this.state.title },
      { name: "message", value: this.state.message },
      { name: "isDailyReminder", value: this.state.daily_reminder },
    ];
    let isUpdateRequest = this.state.submitButtonName === "Update";
    let titleValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[0].value);
    let messageValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[1].value);
    let dailyReminderValidation = { valid: true };
    let filedValidators = [
      titleValidation,
      messageValidation,
      dailyReminderValidation,
    ];
    if (this.state.daily_reminder) {
      if (this.state.recurrence < 1) {
        this.setState({
          repeat_onErrorMessage: "Reccurence can not be less than 1",
        });
        okToSubmit = false;
      } else if (this.state.recurrence > 365) {
        this.setState({
          repeat_onErrorMessage: "Reccurence can not be more than 365",
        });
        okToSubmit = false;
      } else if (!this.state.time) {
        this.setState({ repeat_onErrorMessage: "Time can not be empty." });
        okToSubmit = false;
      } else {
        reminderRec.responses = [
          { recurrence: this.state.recurrence, time: this.state.time },
        ];
      }
    } else {
      if (!this.state.answers.length) {
        this.setState({ repeat_onErrorMessage: "Repeat on can not be empty." });
        okToSubmit = false;
      } else {
        reminderRec.responses = this.state.answers;
      }
    }
    for (let i = 0; i < filedValidators.length; i++) {
      if (filedValidators[i].valid)
        reminderRec[fields[i].name] = fields[i].value;
      else {
        okToSubmit = false;
        this.setState({
          [fields[i].name + "ErrorMessage"]:
            fields[i].name + " can not be empty.",
          open: true,
        });
      }
    }
    this.makeOpenStateFalse();
    let token = JSON.parse(localStorage.session).token;
    if (okToSubmit) {
      if (isUpdateRequest) {
        console.log("update", reminderRec);
        this.setState({ isLoading: true });
        request
          .put("/reminder/" + this.state.selectedReminder, reminderRec, {
            headers: { token },
          })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record updated suceesfully",
              });
              this.getReminderData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      } else {
        reminderRec.user = JSON.parse(localStorage.session).user;
        console.log("submit", reminderRec);
        this.setState({ isLoading: true });
        request
          .post("/reminder", reminderRec, { headers: { token } })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record added suceesfully",
              });
              this.getReminderData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      }
    }
  };
  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };

  clearErrorFields = () => {
    if (this.state.answers.length && this.state.question) {
      this.setState({
        titleErrorMessage: "",
        messageErrorMessage: "",
        daily_reminderErrorMessage: "",
        repeat_onErrorMessage: "",
        answers: [],
      });
    }
  };
  clearStates = (e) => {
    this.setState({
      answers: [],
      title: "",
      submitButtonName: "",
      message: "",
      daily_reminder: true,
      time: "",
      ans: "",
      recurrence: 1,
    });
  };
  render() {
    const fields = [
      {
        name: "title",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.titleErrorMessage,
        value: this.state.title,
      },
      {
        name: "message",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.messageErrorMessage,
        value: this.state.message,
      },
      {
        name: "daily_reminder",
        isInputField: false,
        element: (
          <select
            className="daily_reminder"
            onChange={(e) => this.handleInput(e, "daily_reminder")}
            value={this.state.daily_reminder}
            style={{
              padding: "3px 8px",
              height: "25px",
              border: "1px solid #e5e5e5",
            }}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        ),
        errorMessage: this.state.daily_reminderErrorMessage,
      },
      {
        name: "repeat_on",
        isInputField: false,
        element: (
          <div>
            {this.state.daily_reminder ? (
              <div>
                <div>
                  <span>Every&nbsp;</span>
                  <input
                    style={{
                      width: 50,
                      padding: "3px 8px",
                      height: "20px",
                      border: "1px solid #e5e5e5",
                    }}
                    placeholder="number"
                    type="number"
                    onChange={(e) => this.handleInput(e, "recurrence")}
                    value={this.state.recurrence}
                    min={1}
                    max={365}
                    step={1}
                  />
                  <span>
                    &nbsp;{this.state.recurrence > 1 ? "days" : "day"} at&nbsp;
                  </span>
                  <input
                    type="time"
                    style={{
                      padding: "3px 8px",
                      height: "20px",
                      border: "1px solid #e5e5e5",
                    }}
                    value={this.state.time}
                    onChange={(e) => this.handleInput(e, "time")}
                  />
                </div>
                <div style={{ fontSize: 13, color: "red" }}>
                  {this.state.repeat_onErrorMessage}
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <span>If&nbsp;</span>
                  <input
                    style={{
                      width: 50,
                      padding: "3px 8px",
                      height: "20px",
                      border: "1px solid #e5e5e5",
                    }}
                    placeholder="Ans"
                    type="text"
                    onChange={(e) => this.handleInput(e, "ans")}
                    value={this.state.ans}
                  />
                  <span>&nbsp;repeat after&nbsp;</span>
                  <input
                    type="number"
                    placeholder="num"
                    onChange={(e) => this.handleInput(e, "time")}
                    min={1}
                    max={365}
                    step={1}
                    value={this.state.time}
                    style={{
                      padding: "3px 8px",
                      height: "20px",
                      border: "1px solid #e5e5e5",
                    }}
                  />
                  &nbsp;{this.state.time > 1 ? "days" : "day"}&nbsp;
                  <Button
                    style={{ padding: "3px 8px" }}
                    color="primary"
                    onClick={this.addAnswerToArray}
                  >
                    Add
                  </Button>
                </div>
                <div style={{ fontSize: 13, color: "red" }}>
                  {this.state.repeat_onErrorMessage}
                </div>
                {this.state.answers.map((elm, idx) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                        padding: "5px 0 0 0",
                      }}
                      key={idx}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {"if " +
                          elm.ans +
                          " repeat after " +
                          elm.time +
                          " day(s)"}
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 5px",
                        }}
                      >
                        <DeleteIcon
                          style={{ padding: 3, cursor: "pointer" }}
                          onClick={() => this.deleteAnswer(idx)}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ),
        errorMessage: "",
      },
    ];
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          <Dialog
            buttonName="Add Sweet Score"
            submitButton={{
              name: this.state.submitButtonName,
              functn: () => this.addOrUpdateReminder(),
            }}
            fields={fields}
            open={this.state.open}
            isLoading={this.state.isLoading}
            clearErrorFields={this.clearErrorFields}
            clearStates={this.clearStates}
          />
          <TableList
            tableHead={["Title", "Message", "Daily Reminder", "Repeat On"]}
            tableData={this.state.modifiedReminder}
            tableHeading="Sweet Score"
            tableSubHeading="List of Sweet Score"
            editFunctionality={this.editFunctionality}
            deleteFunctionality={this.deleteFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
