import React, { Component } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Dialog from "@material-ui/core/Dialog";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DataTable from "./Pending";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, SvgIcon, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// import videoIcon from "../../assets/img/Video_Filled.svg";
// import MuiAlert from "@material-ui/lab/Alert"
import "../../assets/css/bookings.css";

/* function Alert(props) {
  return (
    <div style={{ background: "#333", color: "#fff", width: 400 }}>
      test alert
    </div>
  );
}
 */
class Bookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      bookings: [],
      openSnack: false,
      snackMessage: "",
      snackColor: "success",
      page: 0,
      rowsPerPage: 30,
      count: 0,
      selectedFilter: "pending",
      meetingDate: 0,
      meetingTime: 0,
      meetingId: "",
      meetingPassword: "",
      updatedUsers: [],
      searchKey: "",
      data: [],
      mode: "",
      fields: [
        {
          name: "meetingdate",
          type: "date",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingtime",
          type: "time",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingid",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingpassword",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
      ],
    };
    console.log("this.state in getBookings:", this.state);
  }

  resetModal = () => {
    this.setState({ scheduleDialogOpen: false });
    this.setState({
      fields: [
        {
          name: "meetingdate",
          type: "date",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingtime",
          type: "time",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingid",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
        {
          name: "meetingpassword",
          type: "text",
          functn: this.handleInput,
          isInputField: true,
          errorMessage: "",
          value: "",
          error: false,
        },
      ],
    });
  };
  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };
  handleInput = (e, field) => {
    let fields = this.state.fields;
    fields[this.findFieldIndex(fields, field, "name")].errorMessage = "";
    fields[this.findFieldIndex(fields, field, "name")].value = e.target.value;
    fields[this.findFieldIndex(fields, field, "name")].error = false;
    this.setState({ fields });
  };

  componentDidMount = () => {
    this.getBookings();
  };

  getBookings = () => {
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    let page = this.state.page,
      rowsPerPage = this.state.rowsPerPage;
    request
      .get(
        `/booking?filter=${this.state.selectedFilter}&skip=` +
          page * rowsPerPage +
          "&limit=" +
          rowsPerPage,
        {
          headers: {
            token,
          },
        }
      )
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({ count: resp.data.count, data: resp.data.bookings });
          let data = resp.data.bookings;
          console.log("getbooking data", data);
          let bookings = [];
          for (let i = 0; i < data.length; i++) {
            let obj = {};
            obj = data[i];
            obj.id = data[i].id;
            obj.name = data[i].name;
            obj.phone = data[i].phone;
            obj.reasons = data[i].reasons;
            obj.checked = data[i].status === 1 ? true : false;
            bookings.push(obj);
          }
          let count = resp.data.count;
          this.setState(
            { bookings, count },

            () => this.modifyBookingsDataStructure()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };
  sendStatus = (id, status) => {
    let obj = {
      id: id,
      status: status,
    };
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .put(`/booking/status`, obj, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState(
            {
              openSnack: true,
              snackColor: "success",
              snackMessage: "Booking updated suceesfully",
              open: false,
            },
            () => this.getBookings()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  scheduleAppointment = async () => {
    let {
      // meetingDate,
      // meetingTime,
      // meetingId,
      // meetingPassword,
      selectedId,
    } = this.state;
    if (this.state.selectedFilter === "pending") {
      let completedObject = {
        id: selectedId,
        status: "pending",
        meetingDate: this.state.meetingDate,
        meetingTime: this.state.meetingTime,
        meetingId: this.state.meetingId,
        meetingPassword: this.state.meetingPassword,
      };
      console.log("completedObject", completedObject);
      let token = JSON.parse(localStorage.session).token;

      let res = await request.put(`/booking/status`, completedObject, {
        headers: {
          token,
        },
      });

      if (res.status === 200) {
        this.setState({ scheduleDialogOpen: false });
        this.getBookings();
        this.setState({
          openSnack: true,
          snackColor: "success",
          snackMessage: "Updated succesfully",
        });
      } else {
        alert("failed to update the booking");
      }
    } else {
      console.log("this.state in scheduleAppointment:", this.state);
      let okToSubmit = true;
      let fields = this.state.fields;
      console.log("form data", fields);
      console.log("booking state", this.state);

      let dateValidation = this.validate(
        /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
        fields[0].value
      );
      if (!dateValidation.valid) {
        fields[0].error = true;
        fields[0].errorMessage = "date cannot be empty";
        okToSubmit = false;
      }
      console.log("FFF", fields[1].value);
      this.setState({ fields });
      let timeValidation = this.validate(
        /^([0-2][1-9])(:[0-5][0-9])$/,
        fields[1].value
      );
      if (!timeValidation.valid) {
        console.log("TIME", timeValidation);
        fields[1].error = true;
        fields[1].errorMessage = "time cannot be empty";
        okToSubmit = false;
      }
      let meetIdValidation = this.validate(/[0-9]{11}/g, fields[2].value);
      if (!meetIdValidation.valid) {
        fields[2].error = true;
        fields[2].errorMessage = "meeting ID cannot be empty";
        okToSubmit = false;
      }
      let passwordValidation = this.validate(
        /(.|\s)*\S(.|\s)*/,
        fields[3].value
      );
      if (!passwordValidation.valid) {
        fields[3].error = true;
        fields[3].errorMessage = "password cannot be empty";
        okToSubmit = false;
      }
      if (okToSubmit === false) {
        console.log("okToSubmit == false");
        return;
      }
      console.log("okToSubmit == true");
      let {
        // meetingDate,
        // meetingTime,
        // meetingId,
        // meetingPassword,
        selectedId,
      } = this.state;
      this.setState({ isLoading: true });

      let obj = {
        id: selectedId,
        status: "pending",
        meetingDate: fields[0].value,
        meetingTime: fields[1].value,
        meetingId: fields[2].value,
        meetingPassword: fields[3].value,
      };
      console.log("object", obj);
      let token = JSON.parse(localStorage.session).token;

      let res = await request.put(`/booking/status`, obj, {
        headers: {
          token,
        },
      });

      if (res.status === 200) {
        this.setState({ scheduleDialogOpen: false });
        this.getBookings();
        this.setState({
          openSnack: true,
          snackColor: "success",
          snackMessage: "Updated succesfully",
        });
      } else {
        alert("failed to update the booking");
      }
    }
  };

  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };

  modifyBookingsDataStructure = () => {
    let bookings = this.state.bookings;
    let updatedBookings = [];
    for (let i = 0; i < bookings.length; i++) {
      let booking = [];
      booking.push(bookings[i].name);
      booking.push(bookings[i].phone);
      booking.push(bookings[i].reasons.list);
      booking.push(bookings[i].checked);
      updatedBookings.push(booking);
    }
    this.setState({ updatedBookings });
  };

  paginationDetails = (pagination) => {
    let { page, rowsPerPage } = pagination;
    this.setState({ pagination, page, rowsPerPage }, () => this.getBookings());
  };

  handleChange = (event, indx) => {
    let bookings = this.state.bookings;
    bookings[indx].checked = event.target.checked;
    let id = bookings[indx].id;
    let status = event.target.checked ? "completed" : "pending";
    this.setState({ bookings }, () => this.sendStatus(id, status));
  };
  handleFilterChange = (event) => {
    this.setState(
      {
        selectedFilter: event.target.value,
      },
      () => this.getBookings()
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage,
      },
      () => this.getBookings()
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      },
      () => this.getBookings()
    );
  };

  handleSearch = (e) => {
    this.setState({ searchKey: e.target.value });
    this.filterData(this.state.searchKey, this.state.data);
  };

  filterData = (searchKey, data) => {
    let searchInputString = searchKey.trim();
    let arrayData = data;
    if (searchInputString.length) {
      let regexSearchInput = new RegExp(searchInputString, "i");
      let filteredArray = Array.isArray(arrayData[0])
        ? arrayData.filter((eachValue) => {
            for (let property of eachValue) {
              if (property.toString().match(regexSearchInput)) {
                return eachValue;
              }
            }
          })
        : arrayData.filter((eachValue) => {
            for (let property in eachValue) {
              if (typeof eachValue[property] === "object") {
                for (let propety1 in eachValue[property]) {
                  if (
                    eachValue[property][propety1]
                      .toString()
                      .match(regexSearchInput)
                  ) {
                    return eachValue;
                  }
                }
              }
              if (eachValue[property].toString().match(regexSearchInput)) {
                return eachValue;
              }
            }
          });
      this.setState({ bookings: filteredArray });
      return filteredArray;
    }
    return arrayData;
  };

  render() {
    return (
      <div>
        <GridContainer>
          <SnackBar
            open={this.state.openSnack}
            autoHideDuration={2000}
            message={this.state.snackMessage}
            color={this.state.snackColor}
            closeSnack={() => this.setState({ openSnack: false })}
          />
          <GridItem xs={12}>
            <RadioGroup
              style={{ flexDirection: "unset" }}
              aria-label="filter"
              name="filter1"
              value={this.state.selectedFilter}
              onChange={this.handleFilterChange}
            >
              <div>
                <FormControlLabel
                  value="pending"
                  control={<Radio color="primary" />}
                  label="Pending"
                />
              </div>
              <div>
                <FormControlLabel
                  value="completed"
                  control={<Radio color="primary" />}
                  label="Completed"
                />
              </div>
              <div>
                <FormControlLabel
                  value="schedule"
                  control={<Radio color="primary" />}
                  label="Schedule"
                />
              </div>
            </RadioGroup>
            <Card style={{ marginBottom: "0px" }}>
              <CardHeader style={{ display: "flex" }} color="primary">
                <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                  <h4 style={{ display: "flex", flex: 2 }}>Bookings</h4>
                  <input
                    style={{
                      height: "40px",
                      background: "transparent",
                      border: "none",
                      borderBottom: "0.5px solid #fafafa",
                      color: "white",
                    }}
                    id="searchbar"
                    placeholder="Search"
                    onChange={this.handleSearch}
                  />
                </div>
              </CardHeader>
            </Card>
            {this.state.selectedFilter === "pending" && (
              <DataTable
                data={this.state.bookings}
                headers={[
                  { key: "name", displayValue: "Name" },
                  { key: "phone", displayValue: "Phone" },
                  {
                    key: "reasons",
                    displayValue: "Reasons",
                    component: (row) => (
                      <div>
                        {row["reasons"]["list"].map((res, i) => (
                          <div key={"list" + i + row["name"]}>
                            {typeof res === "object"
                              ? res && res["problem"]
                              : res}
                          </div>
                        ))}
                      </div>
                    ),
                  },
                  {
                    key: "meetingDate",
                    displayValue: "Appointment Date",
                    // component: col => <div>{JSON.stringify(col)}</div>
                  },
                  {
                    key: "meetingDate",
                    displayValue: "Action",
                    component: (row) => (
                      <Tooltip title="Video Call">
                        <a
                          href={`https://us04web.zoom.us/j/${
                            row["meetingId"]
                          }?pwd=${row["meetingPassword"]}`}
                          target="_new"
                        >
                          <SvgIcon
                            style={{ padding: "0 5px 0 0", color: "#2962ff" }}
                          >
                            <path d="M17.5683 14.4732L21.4453 17.2022C21.5595 17.253 21.6846 17.2744 21.8091 17.2646C21.9337 17.2547 22.0539 17.2139 22.1586 17.1458C22.2634 17.0777 22.3495 16.9845 22.4092 16.8747C22.4688 16.7649 22.5 16.6419 22.5 16.5169V7.48131C22.5 7.35635 22.4688 7.23336 22.4092 7.12354C22.3495 7.01371 22.2634 6.92051 22.1586 6.85243C22.0539 6.78434 21.9337 6.74351 21.8091 6.73366C21.6846 6.7238 21.5595 6.74523 21.4453 6.796L17.5683 9.52506C17.47 9.59427 17.3897 9.68609 17.3343 9.7928C17.279 9.8995 17.25 10.018 17.25 10.1382V13.8601C17.25 13.9803 17.279 14.0987 17.3343 14.2054C17.3897 14.3121 17.47 14.404 17.5683 14.4732Z" />
                            <path d="M12.5625 18H3.9375C3.29161 17.9981 2.67269 17.7407 2.21598 17.284C1.75926 16.8273 1.50186 16.2084 1.5 15.5625V8.4375C1.50186 7.79161 1.75926 7.1727 2.21598 6.71598C2.67269 6.25926 3.29161 6.00186 3.9375 6H12.585C13.2249 6.00198 13.838 6.25705 14.2905 6.70952C14.743 7.16199 14.998 7.77511 15 8.415V15.5625C14.9981 16.2084 14.7407 16.8273 14.284 17.284C13.8273 17.7407 13.2084 17.9981 12.5625 18Z" />
                          </SvgIcon>
                        </a>
                      </Tooltip>
                    ),
                  },
                  {
                    key: "",
                    displayValue: "Update Appointment",
                    component: (row) => (
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              scheduleDialogOpen: true,
                              selectedId: row["id"],
                              scheduleDialogTitle: "Update Appointment",
                              meetingDate: row["meetingDate"] || 0,
                              meetingTime: row["meetingTime"] || 0,
                              meetingId: row["meetingId"],
                              meetingPassword: row["meetingPassword"],
                              mode: "update",
                            });
                          }}
                        >
                          Update Appointment
                        </Button>
                      </div>
                    ),
                  },
                  {
                    key: "checked",
                    displayValue: "Completed",
                    component: (row) => (
                      <div>
                        <Checkbox
                          color="primary"
                          onClick={() => {
                            this.sendStatus(row["id"], "completed");
                            this.getBookings();
                          }}
                          checked={row["checked"]}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            )}
            {this.state.selectedFilter === "completed" && (
              <DataTable
                data={this.state.bookings}
                headers={[
                  { key: "name", displayValue: "Name" },
                  { key: "phone", displayValue: "Phone" },
                  {
                    key: "reasons",
                    displayValue: "Reasons",
                    component: (row) => (
                      <div>
                        {row["reasons"]["list"].map((res, i) => (
                          <div key={"list" + i + row["name"]}>
                            {typeof res === "object"
                              ? res && res["problem"]
                              : res}
                          </div>
                        ))}
                      </div>
                    ),
                  },

                  {
                    key: "checked",
                    displayValue: "Completed",
                    component: (row) => (
                      <div>
                        <Checkbox
                          color="primary"
                          onClick={() => {
                            this.sendStatus(row["id"], "pending");
                            this.getBookings();
                          }}
                          checked={row["checked"]}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            )}
            {this.state.selectedFilter === "schedule" && (
              <DataTable
                data={this.state.bookings}
                headers={[
                  { key: "name", displayValue: "Name" },
                  { key: "phone", displayValue: "Phone" },
                  {
                    key: "reasons",
                    displayValue: "Reasons",
                    component: (row) => (
                      <div>
                        {row["reasons"]["list"].map((res, i) => (
                          <div key={"list" + i + row["name"]}>
                            {typeof res === "object"
                              ? res && res["problem"]
                              : res}
                          </div>
                        ))}
                      </div>
                    ),
                  },
                  {
                    key: "date",
                    displayValue: "Booking Date",
                    component: (row) => (
                      <div>
                        {`${new Date(row.createdAt).getDate()} - ${new Date(
                          row.createdAt
                        ).getMonth() + 1} - ${new Date(
                          row.createdAt
                        ).getFullYear()}`}
                      </div>
                    ),
                  },

                  {
                    key: "checked",
                    displayValue: "Action",
                    component: (row) => (
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              scheduleDialogOpen: true,
                              selectedId: row["id"],
                              scheduleDialogTitle: "Schedule Appointment",
                              meetingDate: 0,
                              meetingTime: 0,
                              meetingId: "",
                              meetingPassword: "",
                              mode: "add",
                            });
                          }}
                        >
                          <SvgIcon style={{ padding: "0 5px 0 0" }}>
                            <path d="M17.5683 14.4732L21.4453 17.2022C21.5595 17.253 21.6846 17.2744 21.8091 17.2646C21.9337 17.2547 22.0539 17.2139 22.1586 17.1458C22.2634 17.0777 22.3495 16.9845 22.4092 16.8747C22.4688 16.7649 22.5 16.6419 22.5 16.5169V7.48131C22.5 7.35635 22.4688 7.23336 22.4092 7.12354C22.3495 7.01371 22.2634 6.92051 22.1586 6.85243C22.0539 6.78434 21.9337 6.74351 21.8091 6.73366C21.6846 6.7238 21.5595 6.74523 21.4453 6.796L17.5683 9.52506C17.47 9.59427 17.3897 9.68609 17.3343 9.7928C17.279 9.8995 17.25 10.018 17.25 10.1382V13.8601C17.25 13.9803 17.279 14.0987 17.3343 14.2054C17.3897 14.3121 17.47 14.404 17.5683 14.4732Z" />
                            <path d="M12.5625 18H3.9375C3.29161 17.9981 2.67269 17.7407 2.21598 17.284C1.75926 16.8273 1.50186 16.2084 1.5 15.5625V8.4375C1.50186 7.79161 1.75926 7.1727 2.21598 6.71598C2.67269 6.25926 3.29161 6.00186 3.9375 6H12.585C13.2249 6.00198 13.838 6.25705 14.2905 6.70952C14.743 7.16199 14.998 7.77511 15 8.415V15.5625C14.9981 16.2084 14.7407 16.8273 14.284 17.284C13.8273 17.7407 13.2084 17.9981 12.5625 18Z" />
                          </SvgIcon>
                          Schedule Appointment
                        </Button>
                      </div>
                    ),
                  },
                ]}
              />
            )}

            <div
              style={{
                background: "#ffff",
                border: "1px solid #cfcdca",
                borderRadius: "2px",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[10, 15, 30, 60, 100]}
                component="div"
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </GridItem>
          <Dialog
            open={this.state.scheduleDialogOpen}
            onClose={() => {
              this.setState({ scheduleDialogOpen: false });
            }}
            aria-labelledby="form-dialog-title"
          >
            <div
              style={{ width: 800, padding: 20, fontSize: 22, fontWeight: 500 }}
            >
              {this.state.scheduleDialogTitle}
            </div>
            <div>
              {this.state.mode === "add"
                ? this.state.fields.map((field) => {
                    return (
                      <div style={{ padding: 10 }}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                          value={field.value}
                          label={field.name}
                          variant="outlined"
                          onChange={(e) => {
                            field.functn(e, field.name);
                          }}
                          type={field.type}
                          helperText={field.errorMessage}
                          // error={field.error}
                        />
                      </div>
                    );
                  })
                : null}

              {this.state.mode === "update" ? (
                <div>
                  <TextField
                    style={{ width: "100%" }}
                    value={this.state.meetingDate}
                    label="Meeting Date"
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({ meetingDate: e.target.value });
                    }}
                    type={"date"}
                    helperText=""
                    error={false}
                  />
                  <div style={{ padding: 10 }}>
                    <TextField
                      style={{ width: "100%" }}
                      value={this.state.meetingTime}
                      label="Time"
                      variant="outlined"
                      type={"time"}
                      onChange={(e) => {
                        this.setState({ meetingTime: e.target.value });
                      }}
                    />
                  </div>
                  <div style={{ padding: 10 }}>
                    <TextField
                      style={{ width: "100%" }}
                      value={this.state.meetingId}
                      label="Meeting Id"
                      onChange={(e) => {
                        this.setState({ meetingId: e.target.value });
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div style={{ padding: 10 }}>
                    <TextField
                      style={{ width: "100%" }}
                      value={this.state.meetingPassword}
                      onChange={(e) => {
                        this.setState({ meetingPassword: e.target.value });
                      }}
                      label="Meeting Password"
                      variant="outlined"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div style={{ display: "flex", padding: 10 }}>
              <div style={{ flex: 1 }}></div>
              <Button color="primary" onClick={this.scheduleAppointment}>
                {this.state.scheduleDialogTitle}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.resetModal();
                }}
              >
                close
              </Button>
            </div>
          </Dialog>
        </GridContainer>
      </div>
    );
  }
}

export default Bookings;
