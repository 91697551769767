import React from "react";
import Button from "@material-ui/core/Button";
import TableList from "../TableList/TableList.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Dialog from "../../components/Dialog/dialog";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackBar from "../../components/Snackbar/Snackbar";
import request from "../../core/apiClient/request";

export default class SweetScorePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitButtonName: "",
      updatedUsers: [],
      open: false,
      role: "",
      answer: "",
      answers: [],
      sweetScore: [],
      modifiedSweetScore: [],
      openSnack: false,
      snackMessage: "",
      snackColor: "success",
      isLoading: false,
      pagination: {},
      question: "",
      selectedSweet: "",
      reload: false,
    };
  }
  findFieldIndex = (array, field, fieldInArray) => {
    return array.findIndex((ele) => {
      return String(fieldInArray ? ele[fieldInArray] : ele) === String(field);
    });
  };

  handleInput = (e, field) => {
    this.setState({ [field]: e.target.value, [field + "ErrorMessage"]: "" });
  };

  getSweetScoreData = () => {
    let { page, rowsPerPage } = this.state.pagination;
    this.setState({ isLoading: true });
    let token = JSON.parse(localStorage.session).token;
    request
      .get("/sweetScore?skip=" + page * rowsPerPage + "&limit=" + rowsPerPage, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        console.log(resp);
        if (resp.status === 200) {
          this.setState(
            {
              sweetScore: resp.data,
            },
            () => this.modifySweetScoreDataStructure()
          );
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };
  modifySweetScoreDataStructure = () => {
    let sweetScores = this.state.sweetScore;
    let modifiedSweetScore = [];
    for (let i = 0; i < sweetScores.length; i++) {
      let sweetScore = [];
      sweetScore.push(sweetScores[i].question);
      sweetScore.push(sweetScores[i].answersList);
      modifiedSweetScore.push(sweetScore);
    }
    this.setState({ modifiedSweetScore });
  };

  paginationDetails = (pagination) => {
    console.log(pagination);
    this.setState({ pagination }, () => this.getSweetScoreData());
  };

  editFunctionality = (rows) => {
    let sweetScores = this.state.sweetScore;
    let selectedSweet =
      sweetScores[this.findFieldIndex(sweetScores, rows[0], "question")].id;
    this.setState(
      {
        open: true,
        question: rows[0],
        answers: rows[1],
        submitButtonName: "Update",
        selectedSweet,
        questionErrorMessage: "",
        answersListErrorMessage: "",
      },
      () => this.clearErrorFields()
    );
    console.log(rows, selectedSweet);
    this.makeOpenStateFalse();
  };

  makeOpenStateFalse = () => {
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, 100);
  };

  deleteFunctionality = (rows) => {
    let modifiedSweetScore = this.state.modifiedSweetScore;
    let sweetScores = this.state.sweetScore;
    let sweetId =
      sweetScores[this.findFieldIndex(sweetScores, rows[0], "question")].id;
    let token = JSON.parse(localStorage.session).token;
    request
      .delete("/sweetScore/" + sweetId, {
        headers: { token },
      })
      .then((resp) => {
        this.setState({ isLoading: false });
        if (resp.status === 200) {
          this.setState({
            openSnack: true,
            snackColor: "success",
            snackMessage: "Record deleted suceesfully",
          });
          // this.getSweetScoreData();
          let index = this.findFieldIndex(modifiedSweetScore, rows);
          if (index > -1) {
            modifiedSweetScore.splice(index, 1);
          }
          this.setState({ modifiedSweetScore });
        } else {
          this.setState({
            openSnack: true,
            snackColor: "danger",
            snackMessage: String(resp.data),
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          openSnack: true,
          snackColor: "danger",
          snackMessage: String(err),
          isLoading: false,
        });
      });
  };

  addAnswerToArray = () => {
    let answers = this.state.answers;
    if (this.state.answer) {
      answers.push(this.state.answer);
      this.setState({ answer: "", answers, answersListErrorMessage: "" });
    } else {
      this.setState({ answersListErrorMessage: "Answer can not be empty." });
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  deleteAnswer = (id) => {
    let answers = this.state.answers;
    if (id > -1) {
      answers.splice(id, 1);
    }
    this.setState({ answers });
    this.getSweetScoreData();
  };

  addOrUpdateSweetScore = () => {
    console.log("add sweetRec");
    let sweetRec = {};
    let okToSubmit = true;
    let fields = [
      { name: "question", value: this.state.question },
      { name: "answersList", value: this.state.answers },
    ];
    let isUpdateRequest = this.state.submitButtonName === "Update";
    let questionValidation = this.validate(/(.|\s)*\S(.|\s)*/, fields[0].value);
    let answerValidation =
      fields[1].value.length > 0 ? { valid: true } : { valid: false };
    let filedValidators = [questionValidation, answerValidation];
    for (let i = 0; i < filedValidators.length; i++) {
      if (filedValidators[i].valid) sweetRec[fields[i].name] = fields[i].value;
      else {
        okToSubmit = false;
        this.setState({
          [fields[i].name + "ErrorMessage"]:
            fields[i].name + " can not be empty.",
          open: true,
        });
      }
    }
    this.makeOpenStateFalse();
    let token = JSON.parse(localStorage.session).token;
    if (okToSubmit) {
      if (isUpdateRequest) {
        console.log("update", sweetRec);
        this.setState({ isLoading: true });
        request
          .put("/sweetScore/" + this.state.selectedSweet, sweetRec, {
            headers: { token },
          })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record updated suceesfully",
              });
              this.getSweetScoreData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      } else {
        console.log("submit(sweetRecord)", sweetRec);
        this.setState({ isLoading: true });
        request
          .post("/sweetScore", sweetRec, { headers: { token } })
          .then((resp) => {
            this.setState({ isLoading: false });
            if (resp.status === 200) {
              this.setState({
                openSnack: true,
                snackColor: "success",
                snackMessage: "Record added suceesfully",
              });
              this.getSweetScoreData();
            } else {
              this.setState({
                openSnack: true,
                snackColor: "danger",
                snackMessage: String(resp.data),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openSnack: true,
              snackColor: "danger",
              snackMessage: String(err),
              isLoading: false,
            });
          });
      }
    }
  };
  validate = (pattern, field) => {
    const result = pattern.test(field);
    if (result) {
      return { valid: true };
    }
    return { valid: false };
  };

  clearErrorFields = () => {
    if (this.state.answers.length && this.state.question) {
      this.setState({
        questionErrorMessage: "",
        answersListErrorMessage: "",
      });
    }
  };
  clearStates = (e) => {
    this.setState({
      answers: [],
      question: "",
      submitButtonName: "",
    });
  };

  render() {
    const fields = [
      {
        name: "question",
        type: "text",
        functn: this.handleInput,
        isInputField: true,
        errorMessage: this.state.questionErrorMessage,
        value: this.state.question,
      },

      {
        name: "answers",
        isInputField: false,
        element: (
          <div>
            <div>
              <input
                placeholder="answer"
                id="answer"
                onChange={(e) => this.handleInput(e, "answer")}
                value={this.state.answer}
                style={{
                  padding: "3px 8px",
                  height: "20px",
                  border: "1px solid #e5e5e5",
                }}
              />
              &nbsp;
              <Button
                color="primary"
                style={{ padding: "3px 8px" }}
                onClick={this.addAnswerToArray}
              >
                Add
              </Button>
            </div>
            <div style={{ fontSize: 13, color: "red" }}>
              {this.state.answersListErrorMessage}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flex: 1,
                flexDirection: "column",
              }}
            >
              {this.state.answers.map((elm, indx) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      padding: "5px 0 0 0",
                    }}
                    key={indx}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {elm}
                    </span>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: 1,
                        margin: "0 5px",
                      }}
                    >
                      <DeleteIcon
                        style={{ padding: 3, cursor: "pointer" }}
                        onClick={() => this.deleteAnswer(indx)}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ),
        errorMessage: "",
      },
    ];
    return (
      <GridContainer>
        <SnackBar
          open={this.state.openSnack}
          message={this.state.snackMessage}
          color={this.state.snackColor}
          closeSnack={() => this.setState({ openSnack: false })}
        />
        <GridItem xs={12}>
          <Dialog
            buttonName="Add Sweet Score"
            submitButton={{
              name: this.state.submitButtonName,
              functn: () => this.addOrUpdateSweetScore(),
            }}
            fields={fields}
            open={this.state.open}
            isLoading={this.state.isLoading}
            clearErrorFields={this.clearErrorFields}
            clearStates={this.clearStates}
          />
          <TableList
            tableHead={["Question", "Answer"]}
            tableData={this.state.modifiedSweetScore}
            tableHeading="Sweet Score"
            tableSubHeading="List of Sweet Score"
            editFunctionality={this.editFunctionality}
            deleteFunctionality={this.deleteFunctionality}
            paginationDetails={this.paginationDetails}
            isLoading={this.state.isLoading}
          />
        </GridItem>
      </GridContainer>
    );
  }
}
